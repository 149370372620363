import React from "react";
import { ContainerColored } from "../../global";
import { GridItem, GridContainer, WrapperColored, Link } from "./style";
import { Typography } from "@material-ui/core";
import image from "../../../images/getStarted/getStarted.png";

const HowToStart: React.FC = () => {
  return (
    <WrapperColored id="getStarted">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              Get Started
            </Typography>
            <Typography variant="body1">
              You might want to sign up and watch our course on{" "}
              <Link href="/app/course/help/summary" target="_blank">
                how to use Ako Maps
              </Link>
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default HowToStart;
