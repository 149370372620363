import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import HowToStart from "../components/common/getStarted/HowToStart";
import SignUpForCourses from "../components/common/getStarted/SignUpForCourses";
import MakeAMap from "../components/common/getStarted/MakeAMap";
import Footer from "../components/sections/footer";
import ShareYourMap from "../components/common/getStarted/ShareYourMap";
import GetHelp from "../components/common/getStarted/GetHelp";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const GetStartedPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Get Started" />
        <Navigation />
        <HowToStart />
        <SignUpForCourses />
        <MakeAMap />
        <ShareYourMap />
        <GetHelp />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default GetStartedPage;
