import React from "react";
import { ContainerColored } from "../../global";
import image from "../../../images/getStarted/artboard_3.svg";
import { GridItem, GridContainer, WrapperColored } from "./style";
import { Typography } from "@material-ui/core";
const GetHelp: React.FC = () => {
  return (
    <WrapperColored id="getStarted">
      <ContainerColored>
        <GridContainer>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
          <GridItem>
            <Typography variant="h4" style={{ fontWeight: 400 }}>
              Get Help
            </Typography>
            <Typography variant="body1">
              Once you sign up or login, you can click the question mark icon to open the ‘help map’ or send feedback.
            </Typography>
            <Typography variant="body1">
              We also have a live chat available for if you need help or have any questions.
            </Typography>
          </GridItem>
        </GridContainer>
      </ContainerColored>
    </WrapperColored>
  );
};

export default GetHelp;
