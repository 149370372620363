import React from "react";
import { Container } from "../../global";
import { Typography } from "@material-ui/core";
import { GridItem, GridContainer, Wrapper, LinkBlue } from "./style";
import image from "../../../images/getStarted/artboard_2.svg";

const SignUpForCourses: React.FC = () => {
  return (
    <Wrapper id="jumpstart">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              Sign Up For Courses
            </Typography>
            <Typography variant="body1">
              You can search for and sign up to watch <LinkBlue href="/explore">courses</LinkBlue> in the courses page.
              These are Ako Maps that have video lessons or presentations ‘linked’ to them, so you can see information
              in both formats.
            </Typography>
            <Typography variant="body1">
              If you’re on a map with a lesson linked to it, click ‘Course’ in the navigation bar to find them.
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default SignUpForCourses;
