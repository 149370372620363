import React from "react";
import { Container } from "../../global";
import { GridItem, GridContainer, Wrapper, LinkBlue } from "./style";
import { Typography } from "@material-ui/core";
import image from "../../../images/getStarted/artboard_5.svg";

const ShareYourMap: React.FC = () => {
  return (
    <Wrapper id="jumpstart">
      <Container>
        <GridContainer>
          <GridItem>
            <Typography color="primary" variant="h4" style={{ fontWeight: 600 }}>
              Share Your Map
            </Typography>
            <Typography variant="body1">
              You can share your maps with other Ako Map users by clicking ‘File’, ‘Share’, then typing their email
              address and clicking ‘Send share email’.
            </Typography>
            <Typography variant="body1">
              Alternatively, you can share your maps with people without an Ako Maps account by making it public. Click
              ‘Share’, ‘Change to public access’, then send the URL to anyone you like.
            </Typography>
            <Typography variant="body1">
              To learn more about sharing, you might like to visit the{" "}
              <LinkBlue href="https://akomaps.com/app/5f07f6d34b3b0b3bbbb386b4?nodeId=7&levelId=1" target="_blank">
                help map
              </LinkBlue>
              .
            </Typography>
          </GridItem>
          <GridItem>
            <img src={image} alt="" />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};

export default ShareYourMap;
